<template>
  <div class="container">
    <div class="header card">
      <h1>
        <span
          v-lang="
            $route.query.pkFormPersonsId != null
              ? 'persons.title_edit'
              : 'persons.title_add'
          "
        ></span>
      </h1>
      <div class="actions">
        <a class="primary" v-on:click="$router.go(-1)" v-lang="'general.button_back'"></a>
      </div>
    </div>
    <Loader2 v-if="!resultsLoaded" />

    <div class="card" v-else>
      <form
        class="form"
        v-on:submit.prevent="
          $route.query.pkFormPersonsId != null ? update() : save()
        "
        data-vv-scope="person_form"
        v-responsive="{
          small: el => el.width < 985,
          large: el => el.width > 1300
        }"
      >
        <div class="general-data">
          <div
            class="input m-0"
            :class="
              errors.first('person_form.number') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="number">
              <span v-lang="'persons.number'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <input
              class="m-0"
              v-model="model.number"
              type="text"
              name="number"
              autocomplete="off"
              v-validate="'required'"
            />
            <div>
              <span class="error" v-html="errors.first('person_form.number')"></span>
            </div>
          </div>
          <div
            class="input m-0"
            :class="
              errors.first('person_form.fkInstitutionId') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="fkInstitutionId">
              <span v-lang="'persons.institution'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <select
              v-model="model.fkInstitutionId"
              v-validate="'required|excluded:0'"
              name="fkInstitutionId"
              id="dropdownStatusCode"
              :disabled="disabledInstitution"
            >
              <option value="0" selected v-lang="'persons.select_institution'"></option>
              <option
                v-for="(obj, key) in codesModel.institutionCodes"
                :key="key"
                :value="obj.id"
              >{{ obj.name }}</option>
            </select>
            <div>
              <span class="error" v-html="errors.first('person_form.fkInstitutionId')"></span>
            </div>
          </div>
          <div
            class="input m-0"
            :class="
              errors.first('person_form.registrationDate') !== undefined
                ? 'input-error'
                : ''
            "
          >
            <label for="date">
              <span v-lang="'persons.registration_date'"></span>
              <span class="required-field-star">&#x2605;</span>
            </label>
            <date-picker
              name="registrationDate"
              v-model="model.registrationDate"
              :inputClass="'input-date-picker'"
              :locale="lang"
              :initDate="false"
            ></date-picker>
            <div>
              <span class="error" v-html="errors.first('person_form.registrationDate')"></span>
            </div>
          </div>
        </div>
        <fieldset class="fieldset">
          <legend v-lang="'persons.person_data'"></legend>
          <div class="fieldset-main-container">
            <div
              class="input m-0"
              :class="
                errors.first('person_form.fkGenderCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="fkGenderCodeId">
                <span v-lang="'victims.gender'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkGenderCodeId"
                v-validate="'required|excluded:0'"
                name="fkGenderCodeId"
                id="dropdownStatusCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_gender'"></option>
                <option
                  v-for="(obj, key) in codesModel.genderCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                >{{ obj.name }}</option>
              </select>
              <div>
                <span class="error" v-html="errors.first('person_form.fkGenderCodeId')"></span>
              </div>
            </div>
            <div
              class="input m-0"
              :class="
                errors.first('person_form.years') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="years">
                <span v-lang="'victims.years'"></span>
              </label>
              <input
                type="text"
                v-model="model.years"
                maxlength="3"
                oninput="this.value = this.value.replace(/[^0-9]+/, '');"
              />
              <div>
                <span class="error" v-html="errors.first('person_form.years')"></span>
              </div>
            </div>
            <div
              class="input m-0"
              :class="
                errors.first('person_form.fkCitizenshipCodeId') !== undefined
                  ? 'input-error'
                  : ''
              "
            >
              <label for="fkCitizenshipCodeId">
                <span v-lang="'victims.citizenship'"></span>
                <span class="required-field-star">&#x2605;</span>
              </label>
              <select
                v-model="model.fkCitizenshipCodeId"
                v-validate="'required|excluded:0'"
                name="fkCitizenshipCodeId"
                id="dropdownStatusCode"
              >
                <option value="0" selected v-lang="'victims.placeholder_for_citizenship'"></option>
                <option
                  v-for="(obj, key) in codesModel.countryCodes"
                  :key="key"
                  :value="obj.pkCodeId"
                >{{ obj.name }}</option>
              </select>
              <div>
                <span class="error" v-html="errors.first('person_form.fkCitizenshipCodeId')"></span>
              </div>
            </div>
          </div>
          <fieldset class="fieldset">
            <legend v-lang="'victims.exploitation_mode'"></legend>
            <div class="fieldset-container">
              <div
                v-for="(list, key) in codesModel.exploitationModeCodes"
                class="input-checkbox"
                :key="key"
              >
                <label
                  :for="list.pkCodeId"
                  :class="
                    (list.pkCodeId != 1497 &&
                      model.exploitationModes.includes(1497)) ||
                    (list.pkCodeId == 1497 &&
                      (model.exploitationModes.includes(2788) ||
                        model.exploitationModes.includes(2787) ||
                        model.exploitationModes.includes(2789)))
                      ? 'disabled'
                      : ''
                  "
                >
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.exploitationModes"
                    autocomplete="off"
                    :value="list.pkCodeId"
                    :id="list.pkCodeId"
                    hidden
                    :disabled="
                      (list.pkCodeId != 1497 &&
                        model.exploitationModes.includes(1497)) ||
                        (list.pkCodeId == 1497 &&
                          (model.exploitationModes.includes(2788) ||
                            model.exploitationModes.includes(2787) ||
                            model.exploitationModes.includes(2789)))
                    "
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <div class="fieldset-container">
              <div class="input-checkbox">
                <label for="isReportedForFelony">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.isReportedForFelony"
                    id="isReportedForFelony"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'persons.reported_KD'"></span>
                </label>
              </div>
              <div class="input-checkbox">
                <label for="isIndictment">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.isIndictment"
                    id="isIndictment"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'persons.indictment'"></span>
                </label>
              </div>
              <div class="input-checkbox">
                <label for="isFelonyQualifiedAsHumanTrafficking">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.isFelonyQualifiedAsHumanTrafficking"
                    id="isFelonyQualifiedAsHumanTrafficking"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'persons.qualified_as_human_trafficking'"></span>
                </label>
              </div>
              <div class="input-checkbox">
                <label for="finalCourtJudgment">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.finalCourtJudgment"
                    id="finalCourtJudgment"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'persons.final_decision_of_the_institution'"></span>
                </label>
              </div>
              <div class="input-checkbox">
                <label for="courtJudgment">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.courtJudgment"
                    id="courtJudgment"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'persons.judgment'"></span>
                </label>
              </div>
              <div></div>
              <div class="input-checkbox spec-checkbox">
                <label for="fundsFreezing">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.fundsFreezing"
                    id="fundsFreezing"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'persons.freezing_of_funds'"></span>
                </label>
                <div
                  v-if="model.fundsFreezing"
                  class="input m-0 custom-input"
                  :class="
                errors.first('person_form.estimatedValueOfFreezedProperty') !== undefined
                  ? 'input-error'
                  : ''
              "
                >
                  <label for="estimatedValueOfFreezedProperty">
                    <span v-lang="'persons.estimated_value_of_assets'"></span>
                    <span class="required-field-star">&#x2605;</span>
                  </label>
                  <input
                    class="m-0"
                    v-model="model.estimatedValueOfFreezedProperty"
                    type="text"
                    name="estimatedValueOfFreezedProperty"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9\.]+/, '');"
                    onkeypress="if(this.value.length==12) return false;"
                    v-validate="'required'"
                  />
                  <div>
                    <span
                      class="error"
                      v-html="errors.first('person_form.estimatedValueOfFreezedProperty')"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="input-checkbox spec-checkbox">
                <label for="confiscatedFunds">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.confiscatedFunds"
                    id="confiscatedFunds"
                    autocomplete="off"
                    hidden
                  />
                  <span v-lang="'persons.confiscatedFunds'"></span>
                </label>
                <div
                  v-if="model.confiscatedFunds"
                  class="input m-0 custom-input"
                  :class="
                errors.first('person_form.estimatedValueOfConfiscatedProperty') !== undefined
                  ? 'input-error'
                  : ''
              "
                >
                  <label for="estimatedValueOfConfiscatedProperty">
                    <span v-lang="'persons.estimatedValueOfConfiscatedProperty'"></span>
                    <span class="required-field-star">&#x2605;</span>
                  </label>
                  <input
                    class="m-0"
                    v-model="model.estimatedValueOfConfiscatedProperty"
                    type="text"
                    name="estimatedValueOfConfiscatedProperty"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9\.]+/, '');"
                    onkeypress="if(this.value.length==12) return false;"
                    v-validate="'required'"
                  />
                  <div>
                    <span
                      class="error"
                      v-html="errors.first('person_form.estimatedValueOfConfiscatedProperty')"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend v-lang="'persons.prosecution_decision'"></legend>
            <div class="fieldset-container">
              <div
                v-for="(list, key) in codesModel.prosecutionDecisionModes"
                class="input-radio"
                :key="key"
              >
                <label :for="list.pkCodeId">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.prosecutionDecisionModes"
                    :id="list.pkCodeId"
                    :value="list.pkCodeId"
                    autocomplete="off"
                    hidden
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend v-lang="'persons.court_decision'"></legend>
            <div class="fieldset-container">
              <div
                v-for="(list, key) in codesModel.courtJudgmentModes"
                class="input-radio"
                :key="key"
              >
                <label :for="list.pkCodeId">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.courtJudgmentModes"
                    autocomplete="off"
                    :value="list.pkCodeId"
                    :id="list.pkCodeId"
                    hidden
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
              <div
                v-if="model.courtJudgmentModes.includes(2797)"
                class="input m-0 custom-input"
                :class="
                errors.first('person_form.otherCourtJudgment') !== undefined
                  ? 'input-error'
                  : ''
              "
              >
                <label for="otherCourtJudgment">
                  <span v-lang="'persons.court_decision'"></span>
                  <span class="required-field-star">&#x2605;</span>
                </label>
                <input
                  class="m-0"
                  v-model="model.otherCourtJudgment"
                  type="text"
                  name="otherCourtJudgment"
                  autocomplete="off"
                  v-validate="'required'"
                />
                <div>
                  <span class="error" v-html="errors.first('person_form.otherCourtJudgment')"></span>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="fieldset">
            <legend v-lang="'persons.criminal_offense'"></legend>
            <div class="fieldset-container">
              <div v-for="(list, key) in codesModel.felonies" class="input-checkbox" :key="key">
                <label :for="list.pkCodeId">
                  <input
                    class="m-0"
                    type="checkbox"
                    v-model="model.felonies"
                    autocomplete="off"
                    :value="list.pkCodeId"
                    :id="list.pkCodeId"
                    hidden
                  />
                  <span v-html="list.name"></span>
                </label>
              </div>
            </div>
          </fieldset>
        </fieldset>

        <div class="submit">
          <Spinner
            :showSpinner="isSubmited"
            :buttonText="$lang('general.button_submit')"
            :buttonClass="'primary'"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { lang } from "@/global-functions";
import VeeValidate from "vee-validate";
import store from "./store";
import { ResponsiveDirective } from "vue-responsive-components";
import Loader2 from "@/components/General/Loader2";
import globalStore from "@/store";

Vue.use(VeeValidate);

export default {
  directives: {
    responsive: ResponsiveDirective
  },
  components: {
    Loader2
  },
  data() {
    return {
      resultsLoaded: true,
      model: {
        pkFormPersonsId: 0,
        number: null,
        fkInstitutionId: 0,
        fkInstitutionTypeCodeId: 0,
        fkGenderCodeId: 0,
        years: null,
        fkCitizenshipCodeId: 0,
        exploitationModes: [],
        prosecutionDecisionModes: [],
        courtJudgmentModes: [],
        felonies: [],
        isReportedForFelony: false,
        isIndictment: false,
        isFelonyQualifiedAsHumanTrafficking: false,
        finalCourtJudgment: false,
        courtJudgment: false,
        fundsFreezing: false,
        estimatedValueOfFreezedProperty: null,
        otherCourtJudgment: "",
        confiscatedFunds: false,
        estimatedValueOfConfiscatedProperty: null
      },
      validation_messages: {
        custom: {
          number: {
            excluded: lang("validation.required"),
            required: lang("validation.required")
          },
          registrationDate: {
            required: lang("validation.required")
          },
          fkInstitutionId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkGenderCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          fkCitizenshipCodeId: {
            required: lang("validation.required"),
            excluded: lang("validation.required")
          },
          otherCourtJudgment: {
            required: lang("validation.required")
          },
          estimatedValueOfConfiscatedProperty: {
            required: lang("validation.required")
          },
          estimatedValueOfFreezedProperty: {
            required: lang("validation.required")
          }
        }
      },
      disabledInstitution: false,
      codesModel: {
        exploitationModeCodes: [],
        prosecutionDecisionModes: [],
        courtJudgmentModes: [],
        institutionCodes: [],
        genderCodes: [],
        countryCodes: [],
        authorCodes: []
      },
      dateError: {
        field: "registrationDate",
        msg: this.$lang("validation.required"),
        rule: "required",
        scope: "person_form"
      },
      isSubmited: false,
      lang:
        globalStore.state.lang == "sr"
          ? "sr-cyrl"
          : globalStore.state.lang == "bs"
          ? "sr"
          : globalStore.state.lang == "hr"
          ? "hr"
          : "en"
    };
  },
  mounted() {
    this.$validator.localize("en", this.validation_messages);
  },
  created() {
    this.getCodes();
    this.getCodesDropdown();

    if (this.$route.query.pkFormPersonsId != null) {
      this.getByID();
    }
  },
  methods: {
    validateRegistrationDate() {
      if (!this.model.registrationDate) {
        this.errors.add(this.dateError);
      }
    },
    getCodes() {
      this.resultsLoaded = false;
      store
        .dispatch("GET_CODES")
        .then(response => {
          this.codesModel.exploitationModeCodes =
            response.data.model.exploitationModeCodes;
          this.codesModel.prosecutionDecisionModes =
            response.data.model.prosecutionDecisionModes;
          this.codesModel.courtJudgmentModes =
            response.data.model.courtJudgmentModes;
          this.codesModel.felonies = response.data.model.felonies;
          this.resultsLoaded = true;
        })
        .catch(error => {});
    },
    getCodesDropdown() {
      // this.resultsLoaded = false;
      store
        .dispatch("GET_CODES_DROPDOWN")
        .then(response => {
          this.codesModel.institutionCodes =
            response.data.model.institutionCodes;
          this.codesModel.genderCodes = response.data.model.genderCodes;
          this.codesModel.countryCodes = response.data.model.countryCodes;
          this.codesModel.authorCodes = response.data.model.authorCodes;
          if (response.data.model.institutionId != 5) {
            this.disabledInstitution = true;
            this.model.fkInstitutionId = response.data.model.institutionId;
          }
          // this.resultsLoaded = true;
        })
        .catch(error => {});
    },
    save() {
      var $this = this;
      this.validateRegistrationDate();
      this.$validator.validateAll("person_form").then(valid => {
        if (valid && this.model.registrationDate != null && !this.isSubmited) {
          $this.isSubmited = true;
          store
            .dispatch("ADD_PERSON", $this.model)
            .then(response => {
              $this.$success("persons.message_person_successfully_added");
              $this.$router.push({
                name: "PersonsIndex",
                query: {
                  pageNum: 1
                }
              });
              $this.isSubmited = false;
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    update() {
      var $this = this;
      this.validateRegistrationDate();
      this.$validator.validateAll("person_form").then(valid => {
        if (valid && this.model.registrationDate != null && !this.isSubmited) {
          $this.isSubmited = true;
          store
            .dispatch("UPDATE_PERSON", this.model)
            .then(response => {
              this.$success("persons.person_successfully_updated");
              this.$router.go(-1);
              $this.isSubmited = false;
            })
            .catch(error => {
              if (error.status == 400) $this.errorModel = error.data;
            });
        }
      });
    },
    getByID() {
      var $this = this;
      store
        .dispatch("GET_PERSON_BY_ID", $this.$route.query.pkFormPersonsId)
        .then(response => {
          $this.model = response.data.model;
          $this.model.exploitationModes = $this.model.exploitationModes;
          $this.model.prosecutionDecisionModes =
            $this.model.prosecutionDecisionModes;
          $this.model.courtJudgmentModes = $this.model.courtJudgmentModes;
          $this.model.felonies = $this.model.felonies;
        })
        .catch(error => {});
    }
  },
  watch: {
    "model.exploitationModes"() {
      document.getElementById("").disabled = true;
    },
    "model.prosecutionDecisionModes": function(nVal, oVal) {
      if (nVal.length == 2) {
        this.model.prosecutionDecisionModes.splice(0, 1);
      }
    },
    "model.registrationDate": function() {
      if (this.model.registrationDate != null) {
        this.errors.remove("registrationDate", "person_form");
      }
    },
    "$route.query.pkFormVictimsId"() {
      if (this.$route.query.pkFormVictimsId == 0) {
        this.errors.clear();
        this.$validator.reset();
      } else {
        this.getByID();
      }
    },
    "model.estimatedValueOfConfiscatedProperty": function(nVal, oVal) {
      if (nVal[0] == ".") this.model.estimatedValueOfConfiscatedProperty = oVal;
      if (nVal.split(".").length == 3)
        this.model.estimatedValueOfConfiscatedProperty = oVal;
    },
    "model.estimatedValueOfFreezedProperty": function(nVal, oVal) {
      if (nVal[0] == ".") this.model.estimatedValueOfFreezedProperty = oVal;
      if (nVal.split(".").length == 3)
        this.model.estimatedValueOfFreezedProperty = oVal;
    },
    "model.courtJudgmentModes": function(nVal, oVal) {
      if (!this.model.courtJudgmentModes.includes(2797)) {
        this.model.otherCourtJudgment = "";
      }
      if (nVal.length == 2) {
        this.model.courtJudgmentModes.splice(0, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  .general-data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  .submit {
    display: flex !important;
  }
  .fieldset {
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 1/4;
    .fieldset-container {
      // display: grid;
      // grid-gap: 10px;
      // grid-template-columns: 1fr 1fr 1fr;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
  }
  .fieldset-main-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.form.small {
  .general-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .submit {
    display: flex !important;
  }

  .fieldset-main-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}

.custom-input {
  grid-column-start: 1;
}

div.input-checkbox {
  color: black;
  display: -webkit-inline-flex;
  margin: 0px 25px;
  margin-top: 5px;
  label {
    margin-bottom: 10px !important;
  }
}
.spec-checkbox {
  display: flex !important;
  flex-direction: column;
}
.input-radio {
  color: black;
  display: -webkit-inline-flex;
  margin: 0px 25px;
  margin-top: 0px;
  margin-top: 5px;
  margin-bottom: 10px !important;
  input {
    & + span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:checked + span {
      &::before {
        content: "\e945";
        font-size: 15px;
      }
    }
    &:not(:checked) + span {
      &::before {
        content: "\e946";
        font-size: 15px;
      }
    }
  }
}
</style>
