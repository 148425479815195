import Vue from "vue";
import Vuex from "vuex";
import Store from "../../../store";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    GET_CODES: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("form-person/get-codes")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_CODES_DROPDOWN: function(state: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("form-person/get-codes-dropdown")
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    ADD_PERSON: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .post("form-person", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    SEARCH_PERSON: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("form-person", {
            params: model,
          })
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    DELETE_PERSON: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .delete("form-person/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    GET_PERSON_BY_ID: function(state: any, id: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .get("form-person/" + id)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
    UPDATE_PERSON: function(state: any, model: any) {
      return new Promise((resolve, reject) => {
        Store.state.$axios_auth
          .put("form-person", model)
          .then(function(response: any) {
            resolve(response);
          })
          .catch(function(error: any) {
            reject(error.response);
          });
      });
    },
  },
});
